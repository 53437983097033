import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import { AuditTemplateDto, CreateAuditTemplateRequest, Result, UpdateAuditTemplateRequest } from "./contracts/models";

export class AuditTemplateApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AuditTemplate
   * @name List
   * @request GET:/api/audit-templates
   * @response `200` `(AuditTemplateDto)[]` Success
   */
  list = (params: RequestParams = {}) =>
    this.http
      .request<AuditTemplateDto[], any>({
        path: `/api/audit-templates`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags AuditTemplate
   * @name Create
   * @request POST:/api/audit-templates
   * @response `200` `string` Success
   */
  create = (data: CreateAuditTemplateRequest, params: RequestParams = {}) =>
    this.http
      .request<string, any>({
        path: `/api/audit-templates`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags AuditTemplate
   * @name Update
   * @request PUT:/api/audit-templates/{auditTemplateId}
   * @response `200` `Result` Success
   */
  update = (auditTemplateId: string, data: UpdateAuditTemplateRequest, params: RequestParams = {}) =>
    this.http
      .request<Result, any>({
        path: `/api/audit-templates/${auditTemplateId}`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags AuditTemplate
   * @name Delete
   * @request DELETE:/api/audit-templates/{auditTemplateId}
   * @response `200` `void` Success
   */
  delete = (auditTemplateId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/audit-templates/${auditTemplateId}`,
        method: "DELETE",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
}
