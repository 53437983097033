import { QueryClient, useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'
import { RecommendationTemplateDto } from '@/api/contracts/models'
import { useRecommendationTemplateApi } from '@/composables/api/useRecommendationTemplateApi'

export function useRecommendationTemplatesQuery({ enabled } = { enabled: ref(true) }) {
   const client = useRecommendationTemplateApi()
   return useQuery([QueryKeys.RecommendationTemplates], () => client.list(), {
      enabled,
      staleTime: Infinity
   })
}

export function useRecommendationTemplatesQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useRecommendationTemplateApi()
   return queryClient.prefetchQuery<RecommendationTemplateDto[]>(
      [QueryKeys.RecommendationTemplates],
      () => client.list()
   )
}
