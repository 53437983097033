import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { useBusinessGoalDataResultsApi } from '../api/useBusinessGoalDataResultsApi'
import { BusinessGoalResultDto } from '~/api/contracts/models'

export function useBusinessGoalDataResultsQuery() {
   const client = useBusinessGoalDataResultsApi()

   return useQuery([QueryKeys.BusinessGoalDataResults], () =>
      client.list()
   )
}

export function useBusinessGoalDataResultsQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useBusinessGoalDataResultsApi()
   return queryClient.prefetchQuery<BusinessGoalResultDto[]>(
      [QueryKeys.BusinessGoalDataResults],
      () => client.list()
   )
}