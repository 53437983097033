import { QueryClient, useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'
import { AuditTemplateDto } from '@/api/contracts/models'
import { useAuditTemplateApi } from '@/composables/api/useAuditTemplateApi'

export function useAuditTemplatesQuery({ enabled } = { enabled: ref(true) }) {
   const client = useAuditTemplateApi()
   return useQuery([QueryKeys.AuditTemplates], () => client.list(), {
      enabled
   })
}

export function useAuditTemplatesQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useAuditTemplateApi()
   return queryClient.prefetchQuery<AuditTemplateDto[]>(
      [QueryKeys.AuditTemplates],
      () => client.list()
   )
}
